.dashboard {
    &-nav{
        height: 70px;
        border-bottom: 3px solid #F8F8F8;
        display: flex;
        padding: 0 40px;
        @media(max-width:769px){
            padding: 0 20px;
        }

        &-menu{
            width: 35px;
            margin-right: 20px;
            @media(min-width:769px){
                display: none;
            }
        }

        &-status{
            display: flex;

            h3{
                font-size: 17px;
                    font-weight: 400;
                    margin-top: 25px;
                @media(max-width:769px){
                    font-size: 17px;
                    font-weight: 400;
                    margin-top: 25px;
                }
            }


            &-dot{
                margin: 28px 0 0 10px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                @media(max-width:769px){
                    margin: 30px 0 0 5px;
                    width: 7px;
                    height: 7px;
                }
            }
            .not-submitted{
                border: 1px solid #333333;
                background: #333333;
            }
            .under-review{
                border: 1px solid #E7A300;
                background: #E7A300;
            }
            .pending{
                border: 1px solid #E7A300;
                background: #E7A300;
            }
            .Selected{
                border: 1px solid $color-inspiring-purple;
                background: $color-inspiring-purple;
            }
            .Admitted{
                border: 1px solid #29EB7F;
                background: #29EB7F;
            }
            .Rejected{
                border: 1px solid #CC2E2E;
                background: #CC2E2E;
            }
        }

        &-user{
            margin-left: auto;
            display: flex;
            div{
                width: 50px;
                height: 50px;
                border: 1px solid #333333;
                color: white;
                background: #333333;
                border-radius: 50%;
                text-align: center;
                margin-top: 10px;
                @media(max-width:769px){
                    width: 40px;
                    height: 40px;
                    margin-top: 15px;
                }

                h2{
                    margin-top: 10px;
                    font-size: 23px;
                    @media(max-width:769px){
                        font-size: 23px;
                        margin-top: 7px;
                    }
                }
            }
            p{
                font-size: 25px;
                margin: 20px 0 0 20px;
                @media(max-width: 769px){
                    display: none;
                }
            }
        }
    }

    &-content{
        padding: 20px 50px 40px !important;
        @media(max-width:769px){
            padding: 20px 20px 40px !important;
        }
        &-guarantor_paragraph_text{
            margin-bottom: 3rem;
        }
        // a.contact-support{}
        .text1{
            color: #333333 !important;
            font-size: 18px;
            font-weight: 600;
        }
        // .assessment-prompt{
        //     width: 588px;
        //     height: 261px;
        //     padding: 28px;
        // }
        .assessment-backdrop{
            background-color: rgba(0, 0, 0, 0.25);
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }
        .prompt-backdrop{
            background-color: rgba(0, 0, 0, 0.25);
            height: 100%;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }
        .prompt-content{
            width: 588px;
            height: fit-content;
            padding: 28px;
            margin-right: auto;
            margin-left: auto;
            background-color: #fff;
            z-index: 19999;
            border: 1px solid #fff;
            border-radius: 10px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            h2{
                margin: 0;
                font-size: 24px;
                line-height: 32.74px;
                color: #333;
            }
            p{
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #787676;
            }
            span{
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 24px;
                color: #000000;
                cursor: pointer;
            }
            @media screen and (max-width: 768px) {
                width: 80%;
                padding: 16px;
                
            }
            button{
                width: 100%;
                box-sizing: border-box;
            }
        }
        h3, h4{
            color: #333333;
            margin: 30px 0 20px;
            font-weight: 600;
            font-size: 18px;
        }
        p, li{
            font-size: 17px;
            margin-top:1rem;
            color: $color-formgrey;
        }
        ul{
            margin-left: 20px;
        }

        .checkbox{
            margin-left: 20px;
            a{
                color: $color-inspiring-purple;
                text-decoration: underline;
            }
        }

        .disable-btn{
            color: #E0E0E0;
            background: #FAFAFA !important;
            border: 1px solid #FAFAFA;
        }

        button{
            color: white;
            padding: 7px 25px;
            background: $color-inspiring-purple;
            border: 1px solid $color-inspiring-purple;
            font-size: 16px;
            font-weight: 500;
            border-radius: 6px;
            margin: 20px 13px 20px 0px;
            @media(max-width:600px){
                width: 100%;
                box-sizing: border-box;
                margin: 5px 0px;
            }

        }

        &-back{
            display: flex;
            cursor: pointer;
            margin-left: 20px;
            img{
                width: 15px;
            }
            p{
                margin: 5px 0 0 10px;
            }
        }
        &-back2{
            display: flex;
            cursor: pointer;
            margin-left: 20px;
            img{
                width: 15px;
            }
            p{
                margin: 5px 0 0 10px;
            }
            margin-left: 0px;
        }
        .guarantor-form-label{
            padding: 8px 5px;
            border-radius: 28px;
            background-color: $color-inspiring-purple;
            color: #fff;
            font-family: $font-secondary;
            width: 176px;
            text-align: center;
        }

        &-form{
            box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
            border-radius: 15px;
            padding: 40px 40px 100px;
            margin: 0 20px 50px; 
            max-width: 700px;

            @media(max-width:769px){
                padding: 40px 20px 100px;
                margin: 0 0px 50px;
            }

            &-container{
                position: relative;
                box-sizing: border-box;

                label{
                    font-size: 16px;
                    color: #333333;
                    margin-top: 25px;
                }

                input, select, textarea{
                    width: 100%;
                    margin: 5px 0 0;
                    padding: 7px 20px;
                    font-size: 16px;
                    color: $color-formgrey;
                    border-radius: 10px;
                    border: 1px solid #D9DCE1;
                }
                option{
                    // text-transform: capitalize;
                }
                textarea{
                    height: 200px;
                }
                input[type = file] {
                    &::-webkit-file-upload-button {
                        visibility: hidden;
                        position: absolute;
                        right: 0;
                    }
                }

                .upload-text{
                    font-size: 16px;
                    color: $color-formgrey;
                    position: absolute;
                    background: white;
                    bottom: -2px;
                    left: 20px;
                    min-width: 200px;
                }

                .upload-img{
                    width: 20px;
                    position: absolute;
                    bottom: 9px;
                    right: 20px;
                }

                &-prev{
                    color: $color-formgrey !important;
                    background: white !important;
                    border: 1px solid $color-formgrey !important;
                    margin: 20px 15px 20px 0 !important;
                    float:right;
                }

                &-image{
                    width:300px;
                    margin-top: 10px;
                    @media(max-width:400px){
                        width: 100%;
                    }
                }
            }
        }
        &-form2{
            box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
            border-radius: 15px;
            padding: 40px 40px 100px;
            margin: 0 20px 50px; 
            margin-left: 0px;
            max-width: 700px;

            @media(max-width:769px){
                padding: 40px 20px 100px;
                margin: 0 0px 50px;
            }

            &-container{
                position: relative;
                box-sizing: border-box;

                label{
                    font-size: 16px;
                    color: #333333;
                    margin-top: 25px;
                }

                input, select, textarea{
                    width: 100%;
                    margin: 5px 0 0;
                    padding: 7px 20px;
                    font-size: 16px;
                    color: $color-formgrey;
                    border-radius: 10px;
                    border: 1px solid #D9DCE1;
                }
                textarea{
                    height: 200px;
                }
                input[type = file] {
                    &::-webkit-file-upload-button {
                        visibility: hidden;
                        position: absolute;
                        right: 0;
                    }
                }

                .upload-text{
                    font-size: 16px;
                    color: $color-formgrey;
                    position: absolute;
                    background: white;
                    bottom: -2px;
                    left: 20px;
                    min-width: 200px;
                }

                .upload-img{
                    width: 20px;
                    position: absolute;
                    bottom: 9px;
                    right: 20px;
                }

                &-prev{
                    color: $color-formgrey !important;
                    background: white !important;
                    border: 1px solid $color-formgrey !important;
                    margin: 20px 15px 20px 0 !important;
                    float:right;
                }

                &-image{
                    width:300px;
                    margin-top: 10px;
                    @media(max-width:400px){
                        width: 100%;
                    }
                }
            }
        }

        .det-btn-container{
            @media(max-width: 600px){
                overflow-x: scroll;
            }
        }

        &-detail{
            display: flex;
            padding: 10px 20px;
            @media(max-width:600px){
                padding: 10px 0px;
                width: 600px;
                flex-direction: column;
                align-items: flex-start;
            }
        
            &-p{
                padding: 7px 20px;
                margin-right: 10px !important;
                border-radius: 20px !important;
                background: transparent !important;
                color: #787676 !important;
                font-weight: 500;
                border: 1px solid transparent !important;
                cursor: pointer;
            }
            .active{
                color: white !important;
                background: $color-inspiring-purple !important;
            }
        }

        &-modal{
            &-backdrop{
                background: black;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                position: fixed;
                overflow: hidden;
                z-index: 1000;
                opacity: .5;
            }
            &-container{
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                position: fixed;
                overflow: hidden;
                z-index: 1001;
            }
            &-main{
                width: 600px;
                background: white;
                border-radius: 10px;
                position: fixed;
                height: fit-content;
                padding: 0 20px;
                box-sizing: border-box;
                margin: auto;
                bottom: 0;
                left: 0;
                right: 0;
                @media(min-width:769px){
                    top: 0;
                }
                @media(max-width:769px){
                    width: 100%;
                    border-radius: 20px 20px 0 0;
                    margin: 0;
                    height: fit-content;
                }

                &-text{
                    display: flex;
                    img{
                        margin-left: auto;
                    }
                    h4{
                        font-size: 21px;
                    }
                }

                &-frame1{
                    border: 1px solid #E5E9F2;
                    border-radius: 10px;
                    padding: 10px 20px;
                    margin-top: 20px;
                    display: flex;
                    cursor: pointer;

                    div{
                        border: 1px solid #E5E9F2;
                        border-radius: 50px;
                        width: 30px;
                        height: 30px;
                        margin: 5px 15px 0 0;
                    }
                    p{
                        margin-top: 10px;
                        color: #52575C !important;
                    }
                }
                
                .active{
                    border: 1px solid $color-inspiring-purple;
                    border-radius: 10px;
                    padding: 10px 20px;
                    margin-top: 20px;
                    display: flex;
                    cursor: pointer;

                    div{
                        border: 1px solid $color-inspiring-purple;
                        border-radius: 50px;
                        background: $color-inspiring-purple;
                        width: 30px;
                        height: 30px;
                        margin: 5px 15px 0 0;
                    }
                    p{
                        margin-top: 10px;
                        color: #52575C !important;
                    }
                }
                button{
                    width: 100%;
                    font-size: 17px;
                    padding: 10px;
                }
            }
            &-back{
                padding-top: 0;
            }
        }
    }
}
