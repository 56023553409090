.auth {
  &_container{
    &-logo{
      width: 450px;
      margin: 50px auto 20px;
      @media(max-width:560px) {
        width: 100%;
        margin: 30px 20px 0;
      }
    }

    &_form{
      box-shadow: 0px 1px 10px rgba(120, 118, 118, 0.15);
      border-radius: 10px;
      width: 450px;
      display: block;
      margin: 40px auto;
      padding: 30px 40px;
      box-sizing: border-box;
      @media(max-width:560px) {
        box-shadow: none;
        width: 100%;
        padding: 20px 20px 30px;
        margin-top: 20px;
      }

      &-email{
        width: 70px;
      }

      &-text{
        margin-bottom: 20px;
      }

      &-password{
        position: relative;
        div{
          width: 25px;
          position: absolute;
          top: 40px;
          right: 15px;
          img{
            width: 20px;
          }
        }
      }

      h3{
        font-weight: 700;
      }
      p{
        color: $color-formgrey;
      }
      label{
        color: #333333;
      }
      input{
        border: 1px solid #D9DCE1;
        border-radius: 7px;
        padding: 10px 20px;
        margin: 5px 0 20px;
        width: 100%;
        color: $color-formgrey;
        &:focus{
          outline: none;
        }
      }
      select{
        border: 1px solid #D9DCE1;
        border-radius: 7px;
        padding: 10px 20px;
        margin: 5px 0 20px;
        width: 100%;
        color: $color-formgrey;
        &:focus{
          outline: none;
        }
      }
      button{
        background: $color-inspiring-purple;
        border: 1px solid $color-inspiring-purple;
        width: 100%;
        height: 44px;
        padding: 10px 0;
        border-radius: 10px;
        margin: 10px 0 20px;
        text-align: center;
        color: white;
        font-weight: 600;
      }
      span{
        color: $color-inspiring-purple;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  &_verify{
    text-align: center;
    margin: 30px 0;

    img{
      display: block;
      margin: auto;
    }
  }
}
